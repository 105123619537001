import React from 'react';
import { object, string, shape, number } from 'prop-types';

import CardIconDesktop from './cards/card-icon-desktop';
import CardItemDesktop from './cards/card-item-desktop';
import CardMediaDesktop from './cards/card-media-desktop';
import CardGridMediaDesktop from './cards/card-grid-media-desktop';
import CardPosterDesktop from './cards/card-poster-desktop';

import {
  STATIC_ITEM,
  STATIC_ICON,
  STATIC_MEDIA,
  STATIC_ILLUSTRATION,
  ITEMS_IMAGES_GRID,
  DYNAMIC_ACCESS_MODAL,
  DYNAMIC_ACCESS_CUSTOM_CARD,
  STATIC_IMAGES_BUTTON,
  STATIC_IMAGE,
} from '../../../../../utils/constants';

const DynamicAccessCardDesktop = ({ card, sizeCard, index }) => {
  const {
    card_id: cardId,
    content,
    reco_track,
    track,
    type,
    track_event = null,
  } = card;
  const isCardModal = DYNAMIC_ACCESS_MODAL.includes(cardId);
  const isCustomCard = DYNAMIC_ACCESS_CUSTOM_CARD.includes(cardId);

  switch (type) {
    case STATIC_IMAGES_BUTTON: {
      return (
        <CardGridMediaDesktop
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          key={cardId}
          sizeCard={sizeCard}
          index={index}
        />
      );
    }
    case STATIC_ITEM:
    case ITEMS_IMAGES_GRID: {
      return (
        <CardItemDesktop
          key={cardId}
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          sizeCard={sizeCard}
          index={index}
        />
      );
    }
    case STATIC_ICON:
    case STATIC_ILLUSTRATION: {
      return (
        <CardIconDesktop
          key={cardId}
          card={content}
          reco_track={reco_track}
          track={track}
          track_event={track_event}
          isCardModal={isCardModal}
          isCustomCard={isCustomCard}
          type={type}
          sizeCard={sizeCard}
        />
      );
    }
    case STATIC_MEDIA: {
      return (
        <CardMediaDesktop
          key={cardId}
          card={content}
          reco_track={reco_track}
          track={track}
          isCardModal={isCardModal}
          isCustomCard={isCustomCard}
          type={type}
          sizeCard={sizeCard}
        />
      );
    }
    case STATIC_IMAGE: {
      return (
        <CardPosterDesktop
          card={content}
          track={track}
          sizeCard={sizeCard}
          index={index}
        />
      );
    }
    default:
      return null;
  }
};

DynamicAccessCardDesktop.propTypes = {
  card: shape({
    type: string,
    card_id: string,
    content: object,
    track: object,
    track_event: object,
    reco_track: object,
  }),
  sizeCard: string,
  index: number,
};

export default DynamicAccessCardDesktop;
