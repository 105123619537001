import React from 'react';
import classnames from 'classnames';
import { shape, string, bool, object, arrayOf } from 'prop-types';
import { Image } from 'nordic/image';
import { Card as AndesCard } from '@andes/card';
import { Button } from '@andes/button';
import { trackEvent } from '../../../../../services/tracking';
import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import {
  LAZYLOAD_OFF,
  LAZYLOAD_ON,
  ALL_ITEMS_IMAGES,
} from '../../../../../../utils/constants';

const namespace = 'dynamic-access-card-grid-media';

const CardGridMediaDesktop = ({ card, sizeCard, index, track_event }) => {
  const { header, pictures, action, description } = card;

  const itemsImagesLength = pictures && pictures.length <= ALL_ITEMS_IMAGES
    ? pictures.length
    : ALL_ITEMS_IMAGES;

  const handleClick = () => {
    if (track_event) {
      trackEvent(track_event);
    }

    window.location.assign(action.target);
  };

  return (
    <AndesCard
      className={classnames(
        'dynamic-access-card',
        `dynamic-access-card__${sizeCard}`,
        namespace,
      )}
      onClick={handleClick}
    >
      {header && (
        <div className={`${namespace}-header`}>
          <h2 className={`${namespace}__title`}>{header.text}</h2>
        </div>
      )}
      {pictures && (
        <div
          className={`${namespace}__pictures ${namespace}__pictures--${itemsImagesLength}`}
        >
          {pictures.slice(0, 2).map((picture) => (
            <div
              className={`${namespace}__pictures-container`}
              key={picture.id}
            >
              <Image
                src={picture.src}
                alt={picture.alt}
                width="100%"
                height="100%"
                lazyload={index < 6 ? LAZYLOAD_OFF : LAZYLOAD_ON}
                preload
              />
            </div>
          ))}
        </div>
      )}

      {description && (
        <div
          className="dynamic-access-card-ecosistemic-description"
        >
          <span className="dynamic-access-card-ecosistemic__description">
            {description.text}
          </span>
        </div>
      )}
      {action && (
        <Button
          hierarchy={action.label.hierarchy}
          size={action.label.size}
          label={action.label.text}
          className={`${namespace}__action`}
          href={action.target}
        >
          {action.label.text}
          <span className="andes-visually-hidden">{action.label.text}</span>
        </Button>
      )}
    </AndesCard>
  );
};

CardGridMediaDesktop.propTypes = {
  card: shape({
    header: shape({
      text: string,
    }),
    description: shape({
      text: string,
    }),
    lazyLoad: bool,
    pictures: arrayOf(
      shape({
        src: string,
        alt: string,
        id: string,
      }),
    ),
    action: shape({
      label: shape({
        color: string,
        font_family: string,
        font_size: string,
        bg_color: string,
        hierarchy: string,
        size: string,
        text: string,
      }),
      target: string,
    }),
  }),
  track_event: object,
  sizeCard: string,
};

export default withTracker(withTrackerOnPrint(CardGridMediaDesktop));
